export const typeOptions = [
  { value: "AEG Hand Drill", label: "AEG Hand Drill" },
  { value: "Advance Guard Rail", label: "Advance Guard Rail" },
  { value: "Advance Guardrail - Single Pin", label: "Advance Guardrail - Single Pin" },
  { value: "Bag", label: "Bag" },
  { value: "Boots", label: "Boots" },
  { value: "Chain", label: "Chain" },
  { value: "Ear Muffs", label: "Ear Muffs" },
  { value: "Electric Device", label: "Electric Device" },
  { value: "Fire Extinguisher", label: "Fire Extinguisher" },
  { value: "Fleece", label: "Fleece" },
  { value: "Gin Wheel", label: "Gin Wheel" },
  { value: "Hammer Drill", label: "Hamme Drill" },
  { value: "Hard Hat", label: "Hard Hat" },
  { value: "Harness", label: "Harness" },
  { value: "Heavy Duty Wrench", label: "Heavy Duty Wrench" },
  { value: "Jacket", label: "Jacket" },
  { value: "Lanyard", label: "Lanyard" },
  { value: "Mobile/Tablet", label: "Mobile/Tablet" },
  { value: "Portable Flood Light", label: "Portable Flood Light" },
  { value: "Power Cord", label: "Power Cord" },
  { value: "Power Tool", label: "Power Tool" },
  { value: "Ratchet", label: "Ratchet" },
  { value: "Rattle Gun", label: "Rattle Gun" },
  { value: "Reciprocating Saw", label: "Reciprocating Saw" },
  { value: "Rescue Rope", label: "Rescue Rope" },
  { value: "Rope Grab", label: "Rope Grab" },
  { value: "Sling", label: "Sling" },
  { value: "Static Line", label: "Static Line" },
  { value: "Strop", label: "Strop" },
  { value: "T-Shirt", label: "T-Shirt" },
  { value: "Tool Battery", label: "Tool Battery" },
  { value: "Trype 3 Retractable Lanyard", label: "Trype 3 Retractable Lanyard" },
  { value: "Vest", label: "Vest" },
  { value: "Hoist", label: "Hoist" },
];

export const categoryOptions = [
  { value: "Additional Tools", label: "Additional Tools" },
  { value: "Safety Equipment", label: "Safety Equipment" },
  { value: "Specialized PPE", label: "Specialized PPE" },
];

export const categoryDropdowns = [
  "Additional Tools",
  "Safety Equipment",
  "Specialized PPE",
];

export const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const statusDropdowns = [
  "Active",
  "Inactive",
];

export const typeDropdowns = [
  "AEG Hand Drill",
  "Advance Guard Rail",
  "Advance Guardrail - Single Pin",
  "Bag",
  "Boots",
  "Chain",
  "Ear Muffs",
  "Electric Device",
  "Fire Extinguisher",
  "Fleece",
  "Gin Wheel",
  "Hammer Drill",
  "Hard Hat",
  "Harness",
  "Heavy Duty Wrench",
  "Jacket",
  "Lanyard",
  "Mobile/Tablet",
  "Portable Flood Light",
  "Power Cord",
  "Power Tool",
  "Ratchet",
  "Rattle Gun",
  "Reciprocating Saw",
  "Rescue Rope",
  "Rope Grab",
  "Sling",
  "Static Line",
  "Strop",
  "T-Shirt",
  "Tool Battery",
  "Trype 3 Retractable Lanyard",
  "Vest",
];
